@import '~@travel-ui/styles/src/utility';

$headerHeight: 28px + 2 * $spacing-m;

.title {
  font-weight: bold;
  line-height: 28px;
  padding: $spacing-m;
  font-size: $font-saturn;

  @include media(pc) {
    font-size: $font-solar;
    padding-left: 0;
  }

  @include media(sp) {
    background-color: $white;
    position: sticky;
    top: 0;
    z-index: $zIndex-overlay2;
  }
}

.tabContainer {
  width: 100%;
  display: flex;
  align-items: center;
  background: rgba(118, 118, 128, 0.24);
  border-radius: 8.91px;
  padding: 2px;

  .tab {
    @include resetButtonStyle;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    padding: 5px $spacing-l;
    width: 100%;
    border-radius: 8.91px;
    transition: 0.2s all;
    position: relative;

    &:last-child.active::before {
      content: '';
      position: absolute;
      width: 1px;
      background: #8e8e93;
      height: 16px;
      right: 200%;
    }

    &:first-child.active::before {
      content: '';
      position: absolute;
      width: 1px;
      background: #8e8e93;
      height: 16px;
      left: 200%;
    }
  }
  .active {
    background-color: $white;
  }
}

.sortWrapper {
  padding: $spacing-m 0;
  display: flex;
  justify-content: flex-end;
  font-size: $font-venus;
  line-height: $line-height-label;
  font-weight: 400;
  position: relative;
  @include media(sp) {
    padding-top: 0;
  }
  @include media(tl) {
    padding-right: $spacing-m;
  }

  .sortButton {
    @include resetButtonStyle;
    display: flex;
  }
  .sortTitle {
    margin-right: $spacing-s;
  }
  .sortPopup {
    padding: 0;
    top: 30px;
  }
  .sortListItem {
    @include resetButtonStyle;
    width: 100%;
    padding: $spacing-m $spacing-xl;
    display: flex;
    border-bottom: 1px solid $isabelline;
    transition: 0.2s all;

    &:last-child {
      border-bottom: none;
    }
    &:hover {
      background: $isabelline;
    }
  }
}

.list {
  @include media(sp) {
    padding: $spacing-m;
  }

  @include media(pc, tl) {
    padding-bottom: 12px;
  }

  .skeletonItem {
    box-shadow: 0px 1px 0px $pastel-gray;
    border-radius: 12px;
    padding: $spacing-m $spacing-base;
    background-color: $white;
    height: 170px;
  }
}

.noItemContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  height: 657px;
  span {
    padding-top: 25px;
    color: $jet;
    font-weight: bold;
    font-size: $font-neptune;
    line-height: $line-height-title;
    @include media(sp) {
      color: $sonic-silver;
      padding-top: 21px;
    }
  }
  @include media(sp) {
    height: 374px;
  }
}

.loadingContainer {
  width: 100%;
  text-align: center;
}

.tabMenu {
  @include media(sp) {
    padding-left: $spacing-m;
    border-bottom: 1px solid $isabelline;
    position: sticky;
    top: $headerHeight;
    background-color: $white;
    z-index: $zIndex-overlay2;
  }
  @include media(tl) {
    padding-left: $spacing-m;
  }
}

.reservationItemLink {
  outline: none;
}

.mauiContainer {
  padding-bottom: $spacing-m;
  @include media(tl) {
    padding-left: $spacing-m;
  }
  .link {
    @include resetButtonStyle;
    color: $cilantro;
    text-decoration: none;
  }
}

.reservationWrapper {
  @include media(tl) {
    margin: 0 $spacing-m;
  }
}

.detailLink {
  color: $cilantro;
  cursor: pointer;
  padding-bottom: $spacing-base;
  padding-top: $spacing-l;

  @include media(tl, sp) {
    padding-left: $spacing-m;
  }
}
