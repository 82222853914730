@import '~@travel-ui/styles/src/utility';

$default-nav-button-height: 36px;
$carousel-margin: $spacing-08;

.arrowWrapper {
  position: relative;
  width: inherit;
}

.carouselWrapper {
  width: 100%;
  max-width: $pc-max;
  margin: auto;

  @include media(sp) {
    max-width: initial;
  }
}

.carousel {
  @include disableScrollBar-x;
  @include media(sp) {
    margin: $carousel-margin;
  }
}

.carouselButton {
  &#{&} {
    background: $white;
    opacity: 0.8;
    top: 50%;
    transform: translateY(-$default-nav-button-height);
    box-shadow: 0 0 4px $sonic-silver;
    border-radius: 100%;

    svg {
      fill: $black;
    }

    &:hover {
      background: $white;
      opacity: 1;
    }
  }
}

.contents {
  &#{&} {
    display: flex;
    align-items: stretch;
    transform: translateX(0);

    @include move_long(all);

    @include media(sp) {
      :first-child {
        margin-left: 0;
      }

      :last-child {
        margin-right: 0;
      }
    }
  }
}

.buttonWrapper {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;

  @include media(sp) {
    width: calc(100% - ($carousel-margin * 2));
  }
}

.left,
.right {
  position: absolute;
  margin: auto 0;
  z-index: 1;

  & > span {
    position: absolute;
    top: 6px;
    left: 0;
  }
}

.left {
  left: $spacing-08;

  @include media(pc) {
    left: -18px;
  }
}

.right {
  right: $spacing-08;

  @include media(pc) {
    right: -18px;
  }
}
