@import '~@travel-ui/styles/src/utility';

.button {
  @include resetButtonStyle;
  @include border-radius;
  @include box(36px);
}

.icon {
  width: 100%;

  svg {
    @include box($spacing-l);
  }
}

.navigationArrow {
  display: flex;
  align-self: center;
  margin-right: $spacing-base;

  .arrowRight {
    margin-left: auto;
  }
}

.disabled {
  cursor: default;
}

.navigationoutlined {
  &#{&}#{&}#{&}#{&}  {
    margin-right: 0px;
  }
  @include box(36px,40px);
}

.navigationlight {
  svg {
    fill: $cilantro;
  }

  border: 1px solid $cilantro;
  background-color: $white;

  &:hover {
    background-color: $lincoln-green;
  }
}

.arrow-outlined {
  &:hover {
    svg {
      fill: $cilantro;
    }
  }
}

.navigationdark {
  svg {
    fill: $sonic-silver;
  }

  background-color: $black;
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
}

.navigationsimple {
  svg {
    fill: $cilantro;
  }
}

.disabledSimple {
  cursor: default;

  &#{&} {
    svg {
      fill: $pastel-gray;
    }
  }
}

.hover {
  transition: 200ms all ease-in;

  &:hover {
    background-color: $lincoln-green;
  }
}