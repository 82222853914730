@import '~@travel-ui/styles/src/utility';

.card {
  background-color: $white;
  border-radius: 5px 5px 0 0;

  .cardTitle {
    font-weight: 700;
    padding: $spacing-m;
  }
}

.section {
  padding: $spacing-xl 0;
  border-bottom: 4px solid $shady-white;
}

.sectionTitle {
  margin-bottom: $spacing-m;
  font-size: $font-saturn;
  line-height: $line-height-sub-title;
  font-weight: 700;
}

.carousel {
  margin: unset;
  max-width: unset;
}

.carouselButton {
  background: $white;
  opacity: 0.8;

  svg {
    fill: $black;
  }

  &:hover {
    background: $jet;

    svg {
      fill: $white;
    }
  }
}
