@import '~@travel-ui/styles/src/utility';

// Add your CSS here

.container {
  height: 100%;
  width: 100%;
  @include flex-center;
  background-color: $shady-white;
}
