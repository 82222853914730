@import '~@travel-ui/styles/src/utility';

$triangle-spacing: 6px;

.wrapper {
  display: flex;
  flex-wrap: wrap;

  &.vertical {
    flex-direction: column;
  }
}

.itemWrapper {
  position: relative;

  &.clickable {
    cursor: pointer;
  }

  .balloon {
    color: white;
  }

  .popup {
    background: rgba(0, 0, 0, 0.8);
    color: $white;
    cursor: default;
    padding: $spacing-base;
    width: 50vw;

    > span:nth-child(2) {
      padding-top: $spacing-base;
    }

    .title {
      display: block;
      font-weight: bold;
    }

    @include media(tl, pc) {
      width: 20vw;
    }

    &:before {
      content: '';
      position: absolute;
      top: -$triangle-spacing;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 4px $triangle-spacing 4px;
      border-color: transparent transparent rgba(0, 0, 0, 0.8) transparent;
    }
  }
}

.imgIcon {
  // TODO: wil be defined the actual value after get response from QA#1
  height: 16px;
}
