@import '~@travel-ui/styles/src/utility';

.reviewLink {
  @include resetButtonStyle;
  @include font-pc-captions-c1;
  text-align: left;
  color: $current-link-deep-blue;
  cursor: pointer;
  display: flex;
  align-items: center;

  .scoreWrapper {
    flex: 0 0 auto;
    margin-right: $spacing-04;
    text-align: center;
    background-color: $current-link-deep-blue;
    border-radius: $border-radius-4px;
    padding: $spacing-02 $spacing-04;
  }

  .reviewLabelText {
    flex: 1 1 auto;
    display: flex;
    flex-wrap: wrap;
  }

  .reviewCount {
    word-wrap: none;
  }

  .bold {
    @include bold;
  }
}
