@import '~@travel-ui/styles/src/base';

.textCenter {
  text-align: center;
}

.periodTitle {
  font-weight: bold;
  display: block;
  margin-bottom: $spacing-base;
}

.title {
  display: block;
  font-size: $font-solar;
  line-height: $line-height-title;
  color: $jet;

  @include media(sp) {
    font-size: $font-saturn;
  }
}

.descriptionBox {
  @include font-body;
  color: $sonic-silver;
  max-width: 600px;
  width: 100%;
  line-height: 24px;
}

.description {
  display: block;
  margin-top: $spacing-l;
  margin-bottom: $spacing-l;

  @include media(sp) {
    margin-top: $spacing-m;
    margin-bottom: $spacing-m;
  }
}
