@import '~@travel-ui/styles/src/utility';
@import '~@travel-ui/styles/src/base';

#root {
  z-index: 0;
  position: relative;
}

#omni-container {
  z-index: 1;
}

a {
  color: $cilantro;
  cursor: pointer;
}

button {
  @include media(sp) {
    outline: none;
  }
}

@media (pointer: fine) {
  .thin-scrollbar {
    scrollbar-width: thin;
    padding-right: $spacing-08;
  }

  .thin-scrollbar::-webkit-scrollbar {
    height: $spacing-08;
    width: $spacing-08;
  }

  .thin-scrollbar::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }

  .thin-scrollbar::-webkit-scrollbar-thumb {
    background: $cloudy-gray;
    border-radius: 10px;
  }

  .thin-scrollbar::-webkit-scrollbar-thumb:hover {
    background: $sonic-silver;
  }
}
