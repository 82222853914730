@import '~@travel-ui/styles/src/utility';

.container {
  padding: 0 $spacing-m;
  @include media(sp) {
    background-color: $black;
  }
}

.message {
  @include media(pc, tl) {
    margin-top: 60px;
  }
  @include media(sp) {
    padding: $spacing-m;
    color: $white;
    font-weight: 700;
  }
  line-height: 14px;
  svg {
    margin-right: 5px;
    float: left;
  }
}

.item {
  margin-right: $spacing-m;
}

.backButton {
  @include media(pc, tl) {
    width: 50%;
    margin: 0 auto;
    margin-top: $spacing-xl;
    margin-bottom: 60px;
  }
  width: 100%;
}

.buttonContainer {
  display: flex;
  width: 100%;
  padding: $spacing-base;
  position: sticky;
  bottom: 0;
  background-color: $white;
}

.dotContainer {
  height: 40px;
}

.itemContainer {
  padding: $spacing-m;
  overflow: hidden;
  cursor: pointer;
  color: $jet;

  @include media(pc, tl) {
    width: 278px;
    margin-right: $spacing-m;
    min-height: 286px;
    &:hover {
      border-radius: 10px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    }
  }

  @include media(sp) {
    border-top: 1px solid $isabelline;
  }

  .title {
    @include text-truncate(1);
    font-weight: 700;
    margin-bottom: $spacing-base;
  }

  .priceContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: $spacing-m;

    .image {
      width: 120px;
      height: 90px;
      border-radius: 5px;
    }

    .price {
      text-align: right;
      font-size: $font-uranus;
      line-height: $line-height-sub-title-sp;
      font-weight: 700;
      .priceTitle {
        font-size: $font-mars;
        line-height: 14px;
        font-weight: 400;
      }
    }
  }
}
