@import '../guideUserPage.module.scss';

.icon {
  display: flex;
  margin-right: $spacing-m;
  margin-bottom: $spacing-base;
  font-size: $font-mars;

  svg {
    margin-right: 5px;
  }
}

.iconSize {
  font-size: $font-mars;
  height: 12px;
}
