@import '~@travel-ui/styles/src/utility';

.loading {
  position: relative;
  width: 56px;
  height: 56px;
  animation: rotate 3s linear infinite;

  circle {
    width: 100%;
    height: 100%;
    fill: none;
    stroke-width: 8;
    stroke-linecap: round;
    stroke-dasharray: 150;
    animation: animate 3s linear infinite;
  }
}

@keyframes animate {
  0% {
    stroke-dashoffset: 150;
  }
  50% {
    stroke-dashoffset: 50;
  }
  100% {
    stroke-dashoffset: 150;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(-90deg);
  }
  100% {
    transform: rotate(630deg);
  }
}
