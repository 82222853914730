@import '~@travel-ui/styles/src/utility';

.loadingMask-page {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #ffffffdd;
  z-index: 10;
}

.loadingMask-section {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ffffffdd;
}

.flexCenter {
  @include flex-center;
}
