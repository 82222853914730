@import '~@travel-ui/styles/src/utility';
@import '../app.module.scss';

.footerLink {
  color: $jet;
  margin: 0 $spacing-l;
  text-align: center;
  // TODO Q6: If this is confirmed by Design team, replace this with the appropriate styles for link, active, hover and others
  text-decoration: none;

  &:hover {
    color: $lincoln-green;
  }

  &:first-child {
    margin-left: 0;
  }
}

.cookieButton {
  // overwrite styles from OneTrust
  background-color: transparent !important;
  border: none !important;
  color: $jet !important;
  margin-right: $spacing-16;
  padding: 0 !important;
  font-size: $font-venus !important;
  text-align: center;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: $lincoln-green !important;
  }
}

.mainContent,
.noFixedHeaderContent {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
  word-break: break-word;
  white-space: pre-line;
}

.mainContent {
  @include media(tl, pc) {
    margin-top: $traveler-header-height-pc;
  }
  @include media(pc) {
    @include pc-layout;
  }
}

.layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.disable {
  cursor: none;
  pointer-events: none;
}

.extendedAppDownloadBanner {
  @include media(sp) {
    margin-bottom: 60px;
  }
}
