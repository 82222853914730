@import '~@travel-ui/styles/src/utility';

.wrapper {
  overflow: hidden;
  display: flex;
  width: 100%;

  &.direction--column {
    flex-direction: column;
    > *:not(:last-child) {
      margin-bottom: $spacing-m;
    }
  }

  &.direction--row {
    flex-direction: row;
    > *:not(:last-child) {
      margin-right: $spacing-m;
    }
  }
}

.item {
  display: flex;
  width: 100%;

  &.layout-style {
    &--textWithImageRow {
      flex-direction: row;
    }

    &--textWithImageColumn {
      flex-direction: column;
    }
  }
}

.image {
  width: 100%;
  height: 100%;
  flex-shrink: 0;

  &.image-style {
    &--textWithImageRow {
      width: 30%;
      height: 120px;
      margin-right: $spacing-base;
    }

    &--textWithImageColumn {
      height: 120px;
      margin-bottom: $spacing-m;
    }
  }
}
