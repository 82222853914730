@import '../guideUserPage.module.scss';

.review {
  margin-bottom: $spacing-base;
}

.itemContainer {
  @include media(pc, tl) {
    min-height: 0px;
  }
}
