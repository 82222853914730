@import '~@travel-ui/styles/src/utility';
@import '../../core/App/app.module.scss';

.skeletonWrapper {
  height: 100%;
  width: 100%;
  padding: $spacing-16;
  @include media(pc) {
    @include pc-layout;
  }
}

.skeleton {
  padding: $spacing-m 0 0;
  max-height: 200px;
}

.paragraphSkeleton {
  padding: $spacing-m 0 0;
  max-height: 200px;
}

.items {
  :nth-child(n) {
    margin: $spacing-m 0 0;
  }

  @include media(pc, tl) {
    display: flex;
    justify-content: space-between;
  }
}
