@import '~@travel-ui/styles/src/utility';

$default-padding-top: 40px;

.wrapper {
  padding: $default-padding-top 140px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  @include media(tl, pc) {
    padding-top: $default-padding-top;
  }

  @include media(sp) {
    padding-left: $spacing-m;
    padding-right: $spacing-m;
  }
}

.icon {
  margin-bottom: $spacing-xl;
}

.mainText {
  margin-bottom: $spacing-m;
  max-width: 768px;
  text-align: center;
  font-size: $font-saturn;
  line-height: 24px;
  font-weight: bold;
}

.subText {
  color: $sonic-silver;
  text-align: center;
  margin-bottom: $spacing-m;
}

.link {
  color: $cilantro;
  cursor: pointer;
}
