@import '~@travel-ui/styles/src/utility';
@import '../../../../core/App/app.module.scss';

.container {
  @include font-sub-body;
  padding: $spacing-base;
  background-color: $white;
  text-align: left;
  color: $jet;
  position: relative;

  @include media(tl, pc) {
    padding: $spacing-m 0;
    border-top: 4px solid $shady-white;
  }

  @include media(sp) {
    padding: $spacing-m;
    margin-bottom: $spacing-m;
    border: 1px solid $isabelline;
    box-sizing: border-box;
    border-radius: 12px;
  }

  .titleAndRegion {
    text-align: left;
    margin-bottom: $spacing-base;
  }

  .imageAndPrice {
    @include media(sp) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .itemImg {
      height: auto;
      width: 100%;
      border-radius: 10px;
      cursor: pointer;
      margin-right: $spacing-base;
      margin-bottom: $spacing-base;
      overflow: hidden;

      @include media(tl) {
        @include box4by3(calc(100vw / 4.4));
      }

      @include media(pc) {
        @include box4by3(calc(#{$max-content-width--pc} / 4.735));
      }

      @include media(sp) {
        @include box16by9(calc(100vw / 3));
      }
    }
  }

  .itemDetails {
    text-align: left;

    @include media(tl, pc) {
      margin-left: $spacing-m;
      width: 100%;
    }

    .amount {
      color: $sonic-silver;
      margin-bottom: $spacing-base;
    }
  }

  .detailLink {
    color: $cilantro;
    cursor: pointer;
    font-weight: bold;
    font-size: $font-mars;
    display: inline-flex;
    justify-content: flex-end;
    padding-bottom: $spacing-m;

    @include media(tl, pc) {
      padding-top: $spacing-base;
      padding-bottom: 0;
    }
  }
}

.child {
  padding-left: 2px;
}

.roomAndPrice {
  @include media(pc, tl) {
    display: flex;
  }
}

.ResInfo {
  @include font-body;
  padding-bottom: $spacing-base;
  display: flex;
  > span {
    &:first-child {
      min-width: 180px;
      width: 180px;
      display: inline-block;
      font-weight: bold;
      margin-right: $spacing-m;
    }
  }

  .itemName {
    padding-bottom: $spacing-base;
  }
}

.ratePlan {
  @include text-truncate(2);
  font-size: $font-venus;
  line-height: 24px;
  color: $jet;
}

.itemName {
  @include text-truncate(2);
  @include font-label;
}

.date {
  padding-top: $spacing-base;
  font-size: $font-mars;
  line-height: 16px;
  color: $sonic-silver;
}

.translateBadge {
  margin-top: $spacing-base;
}

.moreButton {
  @include resetButtonStyle;
  height: 16px;
}
// Select Popup
$popup-pointer-size: 15px;
.popup {
  padding: 0;
  top: 50px;
  right: 0;
}

.navigationWrapper {
  text-align: left;
}

.providerNameWrapper {
  @include text-truncate(2);
  width: 100%;
  flex-grow: 1;
  font-weight: bold;
  text-align: left;
}

.providerName {
  @include font-body;
  font-weight: bold;
  text-align: left;
  width: 100%;

  @include media(tl, pc) {
    font-size: $font-neptune;
  }
}

.content {
  @include text-truncate(2);
}

.button {
  max-width: unset;
  width: 100%;
  padding: $spacing-s 10px;

  @include media(sp) {
    @include resetButtonStyle;
    width: 100%;
    padding: $spacing-m $spacing-xl;
    display: flex;
    border-bottom: 1px solid $isabelline;
    transition: 0.2s all;
    font-weight: normal;
    color: $jet;

    &:hover {
      background: $isabelline;
    }
  }

  @include media(tl, pc) {
    width: 180px;
  }
}

.buttonWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.popupButton {
  font-weight: normal;
  color: $jet;
  border: none;
}
