@import '../guideUserPage.module.scss';

.tag {
  padding: 1px $spacing-base;
  font-size: $font-mars;
  line-height: 16px;
  background-color: $scarlet;
  color: $white;
  font-weight: 400;
  margin-bottom: $spacing-s;
}

.icon {
  display: flex;
  margin-right: $spacing-m;
  margin-bottom: $spacing-base;
  font-size: $font-mars;
  svg {
    margin-right: 5px;
  }
}

.iconSize {
  font-size: $font-mars;
  height: 12px;
}

.scarlet {
  color: $scarlet;
}

.delLine {
  text-decoration-line: line-through;
}

.planItemFreeUntil {
  font-size: $font-mars;
  margin-bottom: $spacing-base;

  @include media(sp) {
    margin-bottom: $spacing-base;
  }

  .date {
    color: $sonic-silver;
  }
}

.planItemPoint {
  display: inline-flex;
  font-size: $font-mars;
  margin-bottom: $spacing-base;

  svg {
    margin-left: 0;
    margin-right: 5px;
    g > g > path {
      fill: $shady-cardinal;
    }
  }
}

.couponWrapper {
  @include font-sub-body;
  color: $apple-red;
  cursor: pointer;
  display: inline-flex;
  flex-shrink: 0;

  .couponIcon {
    display: inline-block;
    margin-right: $spacing-s;
  }

  > .text {
    margin-left: $spacing-s;
    text-decoration: underline;
    display: inline-flex;
    align-items: center;

    .subtitle {
      display: flex;
    }

    .highlight {
      font-weight: bold;
    }
  }
}
