@import '~@travel-ui/styles/src/utility';

$circleSize: 8px;
$circleGap: 4px;
$small-ui-circleSize: 4px;
$small-ui-circleGap: 3px;

.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.container {
  display: flex;
  height: 100%;
  will-change: transform;

  @include move_long(all);
  @include media(pc) {
    touch-action: none;
  }
}

.sliderItem {
  user-select: none;
  display: inline-block;
  flex-shrink: 0;
  will-change: transform;
}

// pagination
.bottomWrapper,
.bottomWrapperSmall {
  position: absolute;
  bottom: $spacing-m;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  text-align: center;
  color: $white;
  @include font-sub-body;

  .centerWrap {
    display: flex;
    align-items: center;
    justify-content: center;

    .botIcon {
      margin: 0 $spacing-m;
      cursor: pointer;
    }
  }
}

.bottomWrapperSmall {
  bottom: $small-ui-circleGap;
}

.circleWrap,
.circleSmallWrap {
  max-width: calc((#{$spacing-s} + #{$circleSize}) * 5);
  overflow: hidden; // this is here as a fallback for iOS where clip is not supported
  overflow: clip;
  display: flex;
}

.circleSmallWrap {
  max-width: calc((#{$small-ui-circleGap} + #{$small-ui-circleSize}) * 5);
}

.circleBox {
  @include box($circleSize);
}

.circleSmallBox {
  @include box($small-ui-circleSize);
}

.circles,
.circlesSmall {
  display: flex;
  gap: $spacing-s;
  justify-content: center;
  @include move_long(transform);

  .smallCircle {
    @include box($circleSize);
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.6);
    @include move_long(scale);

    &.activeCircle {
      background: rgba(255, 255, 255, 1);
    }
  }

  .circleColor {
    background: rgba(109, 109, 109, 0.5);

    &.cursorStyle {
      cursor: pointer;
    }

    &.activeCircle {
      background: rgba(109, 109, 109);
    }
  }

  .smallerCircle {
    transform: scale(0.875);
  }

  .tinyCircle {
    transform: scale(0.75);
  }
}

.circlesSmall {
  gap: $small-ui-circleGap;

  .smallCircle {
    @include box($small-ui-circleSize);
  }

  .smallerCircle {
    transform: scale(1);
  }
}

.arrow,
.arrowSmall {
  @include resetButtonStyle;
  @include box(28px);
  cursor: pointer;
  border-radius: 3px;
  background: rgb(255, 255, 255);
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 1;
  border-radius: 50%;
  opacity: 0.8;
  box-shadow: transparent 0px 0px 0px 1px, transparent 0px 0px 0px 4px,
    rgba(0, 0, 0, 0.18) 0px 2px 4px;
  @include move_long(opacity);

  &:hover {
    opacity: 1;
    box-shadow: transparent 0px 0px 0px 1px, transparent 0px 0px 0px 4px,
      rgba(0, 0, 0, 0.12) 0px 6px 16px;
  }

  &.next {
    right: $spacing-base;
  }

  &.prev {
    left: $spacing-base;
  }

  .icon {
    display: inline-block;
    vertical-align: middle;
  }

  &.hidden {
    opacity: 0;
  }
}

.arrowSmall {
  @include box(16px);

  &.next {
    right: $spacing-s;
  }

  &.prev {
    left: $spacing-s;
  }
}

.navButtonWrapper {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;

  .navButton {
    @include resetButtonStyle;
    @include border-radius;
    @include box(36px);

    background: $white;
    opacity: 0.6;
    top: 60%;
    transform: translateY(-36px);
    box-shadow: 0 0 4px $sonic-silver;
    border-radius: 100%;

    &:hover {
      background: $white;
      opacity: 1;
      transition: 200ms all ease-in;
    }
  }

  .navIcon {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      @include box($spacing-24);
    }
  }

  .left,
  .right {
    position: absolute;
    margin: auto 0;
    z-index: 1;
  }

  .left {
    left: $spacing-08;

    @include media(pc) {
      left: -18px;
    }
  }

  .right {
    right: $spacing-08;

    @include media(pc) {
      right: -18px;
    }
  }
}
